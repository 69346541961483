<template>
  <div class="search">
    <div class="search-field" :class="{ active: showSearchPanel }">
      <input
        type="text"
        name=""
        id=""
        placeholder="Search"
        v-model="searchQuery"
        @focus="showSearchPanel = true"
      />
      <button>
        <img :src="require(`@/assets/img/icon-search.svg`)" alt="" srcset="" />
      </button>
    </div>

    <transition name="fade">
      <div class="results" v-if="showSearchPanel">
        <ul class="results-list">
          <li v-for="nft in queriedNFT" :key="nft.dna">
            <router-link
              :to="`/marketplace/${nft.dna}`"
              class="d-flex justify-content-between align-items-center"
            >
              <div class="d-flex align-items-center">
                <div class="image">
                  <img
                    :src="
                      require(`@/assets/img/marketplace/sale/${nft.edition}.png`)
                    "
                    alt=""
                  />
                </div>
                <div class="name-types">
                  <div class="name d-flex align-items-center">
                    <span class="me-1">{{ nft.name }}</span>

                    <img
                      :src="
                        require(`@/assets/img/icon-rarity-${nft.properties.rarity}.svg`)
                      "
                      alt=""
                    />
                  </div>

                  <ul class="types-list">
                    <li>
                      <img
                        :src="
                          require(`@/assets/img/filter-type-${nft.properties.type.toLowerCase()}.png`)
                        "
                        alt=""
                      />
                    </li>
                    <li>
                      <img
                        :src="
                          require(`@/assets/img/icon-specialist-${nft.properties.specialist.toLowerCase()}.svg`)
                        "
                        alt=""
                      />
                    </li>
                  </ul>
                </div>
              </div>

              <div class="abbilities">
                <ul>
                  <li>
                    <div class="icon">
                      <img
                        :src="require(`@/assets/img/icon-properties-level.svg`)"
                        alt=""
                      />
                    </div>
                    <span>{{ nft.properties.level }}</span>
                  </li>

                  <li>
                    <div class="icon">
                      <img
                        :src="
                          require(`@/assets/img/icon-properties-energy.svg`)
                        "
                        alt=""
                      />
                    </div>
                    <span>{{ 300 }}</span>
                  </li>

                  <li>
                    <div class="icon">
                      <img
                        :src="require(`@/assets/img/icon-properties-luck.svg`)"
                        alt=""
                      />
                    </div>
                    <span>{{ nft.properties.luck }}</span>
                  </li>

                  <li>
                    <div class="icon">
                      <img
                        :src="
                          require(`@/assets/img/icon-properties-efficiency.svg`)
                        "
                        alt=""
                      />
                    </div>
                    <span>{{ nft.properties.efficiency }}</span>
                  </li>

                  <li>
                    <div class="icon">
                      <img
                        :src="
                          require(`@/assets/img/icon-properties-energy-efficiency.svg`)
                        "
                        alt=""
                      />
                    </div>
                    <span>{{ nft.properties.energyEfficiency }}</span>
                  </li>
                </ul>
              </div>
            </router-link>
          </li>
        </ul>

        <a
          herf="#"
          class="load-more"
          v-if="pagination.pageCount > pagination.page"
          @click.prevent="pagination.page++"
        >
          load more...
        </a>

        <div class="no-result" v-if="queriedNFT.length == 0">
          <img
            :src="require(`@/assets/img/empty-search.png`)"
            alt=""
            srcset=""
          />

          <span>No Result</span>
        </div>
      </div>
    </transition>

    <div
      class="search-toggle"
      :class="{ active: showSearchPanel }"
      @click="showSearchPanel = !showSearchPanel"
    >
      <i class="fa-solid fa-magnifying-glass"></i>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

export default {
  name: "Search",
  data() {
    return {
      sad: 0,
      showSearchPanel: false,
      searchQuery: "",
      searchResults: {
        gotchi: [],
      },
      pagination: {
        nftPerPage: 20,
        page: 1,
        pageCount: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["nftArray"]),
    queriedNFT: function () {
      let newArr = this.nftArray.filter(
        (nft) =>
          nft.name.toLowerCase().indexOf(this.searchQuery.toLowerCase()) >= 0
      );

      if (newArr.length > 0) {
        this.pagination.pageCount = Math.ceil(
          newArr.length / this.pagination.nftPerPage
        );
      } else {
        this.pagination.pageCount = 0;
      }

      newArr.sort((a, b) => {
        return a.edition - b.edition;
      });

      newArr = newArr.slice(
        0,
        this.pagination.nftPerPage * this.pagination.page
      );

      return newArr;
    },
  },
  created() {
    document.addEventListener("click", (event) => {
      let el = document.querySelector(".search");

      if (!(el === event.target || el.contains(event.target))) {
        this.showSearchPanel = false;
      }
    });
  },
};
</script>

<style lang="scss">
.search {
  position: relative;

  .search-field {
    @media screen and (max-width: 767.98px) {
      display: none;
      position: fixed;
      bottom: 26px;
      right: 20px;
      z-index: 999;

      &.active {
        display: block;
      }
    }

    input[type="text"] {
      border: none;
      background: #eff3f9;
      border-radius: 16px;
      height: 46px;
      padding: 10px 30px 10px 16px;
      min-width: 280px;
      font-size: 14px;
      transition: all 0.3s;
      border: 1px solid #eff3f9;

      @media screen and (max-width: 475px) {
        box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.15) !important;
      }

      &::placeholder {
        color: #5e5873;
      }

      &:focus {
        border-color: #3f81e0;
        box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
      }
    }

    button {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      display: flex;
    }
  }

  .results {
    position: absolute;
    top: calc(60px + 10px);
    width: 500px;
    height: 400px;
    background: #fff;
    box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.15);
    border-radius: 20px;
    overflow-y: scroll;

    @media screen and (max-width: 767.98px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 99;
      border-radius: 0;
      padding-top: 20px;
    }

    &-list {
      li {
        a {
          padding: 0 20px;

          @media screen and (max-width: 475px) {
            flex-wrap: wrap;
            padding: 10px 20px;
          }

          &:hover {
            background-color: rgba(#3f81e0, 0.12);
          }

          .image {
            margin-right: 8px;

            img {
              max-width: 60px;
            }
          }

          .name-types {
            .name {
              margin-bottom: 4px;

              span {
                font-size: 14px;
                font-weight: bold;
                color: #161d2f;
              }

              img {
                max-height: 18px;
              }
            }

            .types-list {
              display: flex;
              align-items: center;

              li {
                &:not(:last-of-type) {
                  margin-right: 6px;
                }

                img {
                  max-height: 18px;
                }
              }
            }
          }

          .abbilities {
            @media screen and (max-width: 767.98px) {
              background: #eff3f9;
              padding: 6px;
              border-radius: 10px;
            }

            @media screen and (max-width: 475px) {
              width: 100%;
            }

            ul {
              display: flex;

              @media screen and (max-width: 475px) {
                justify-content: center;
              }

              li {
                display: flex;
                align-items: center;

                &:not(:last-of-type) {
                  margin-right: 10px;
                }

                .icon {
                  margin-right: 6px;

                  img {
                    max-height: 18px;
                  }
                }

                span {
                  color: #161d2f;
                  font-size: 14px;
                  font-weight: bold;
                }
              }
            }
          }
        }
      }
    }

    .load-more {
      display: block;
      cursor: pointer;
      text-align: center;
      color: #3f81e0;
      padding: 10px 0 20px;

      @media screen and (max-width: 767.98px) {
        padding-bottom: 100px;
      }

      span {
        font-size: 16px;
      }

      &:hover {
        opacity: 0.7;
      }
    }

    .no-result {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      img {
        max-width: 160px;
        margin-bottom: 10px;
      }

      span {
        font-size: 22px;
        font-weight: 500;
      }
    }
  }

  .search-toggle {
    position: fixed;
    left: 20px;
    bottom: 20px;
    width: 60px;
    height: 60px;
    border-radius: 40px;
    background: #3f81e0;
    color: #fff;
    z-index: 99;
    font-size: 20px;
    cursor: pointer;

    display: none;
    align-items: center;
    justify-content: center;
    transition: all 0.3s;

    @media screen and (max-width: 767.98px) {
      display: flex;
    }

    &.active {
      background: #e64335;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>