import { createStore } from 'vuex'

import coinModule from './modules/coin.store'

export default createStore({
  state: {
    isExpanded: false,
    hoverExpand: false,
    userWallet: {
      conected: false,
      address: null,
    },
    nftArray: []
  },
  actions: {
  },
  getters: {
    isExpanded(state) {
      return state.isExpanded
    },
    hoverExpand(state) {
      return state.hoverExpand
    },
    userWallet(state) {
      return state.userWallet
    },
    nftArray(state) {
      return state.nftArray
    }
  },
  mutations: {
    SET_NFTS: (state, nfts) => {
      state.nftArray = nfts;
    },
    expandeToggle(state, value) {
      state.isExpanded = value
    },
    hoverExpandToggle(state, value) {
      state.hoverExpand = value
    },
    userWalletUpdate(state, value) {
      if (value) {
        state.userWallet.conected = true;
        state.userWallet.address = value;
      } else {
        state.userWallet.conected = false;
        state.userWallet.address = null;
      }
    }
  },
  modules: {
    coinModule
  }
})
