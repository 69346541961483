/**
 * API path
 */

let apiURL;

if (process.env.NODE_ENV === 'development') {
  apiURL = process.env.VUE_APP_API_PATH_DEV;
} else {
  apiURL = process.env.VUE_APP_API_PATH;
}

export { apiURL }