
<template>
  <div class="main-menu" :class="{ expanded: isExpanded }">
    <div class="main-menu-header"></div>

    <div class="main-menu-content">
      <div class="navigation-group">
        <ul class="menu">
          <!-- <li class="menu-item">
            <router-link to="/">
              <svg
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 4.83889V20.1611C0 21.4445 0.509645 22.6753 1.41731 23.5827C2.32469 24.4901 3.55543 25 4.8386 25H19.3544C20.6375 25 21.8683 24.4901 22.7757 23.5827C23.6833 22.6753 24.193 21.4446 24.193 20.1611V4.83889C24.193 3.55551 23.6833 2.32469 22.7757 1.41731C21.8683 0.509925 20.6376 0 19.3544 0H4.8386C3.55551 0 2.32469 0.509925 1.41731 1.41731C0.509709 2.32469 0 3.55543 0 4.83889ZM4.83853 3.22616L10.4837 3.22588V13.3063H3.2258V4.83875C3.2258 4.41095 3.39569 4.00056 3.69804 3.69822C4.00068 3.39559 4.41076 3.2257 4.83857 3.2257L4.83853 3.22616ZM3.22585 20.1608V16.532H10.4837V21.7739H4.83857C4.41077 21.7739 4.00067 21.604 3.69804 21.3014C3.39569 20.999 3.2258 20.5886 3.2258 20.1608L3.22585 20.1608ZM19.3545 21.7736L13.7093 21.7738V11.6934H20.9672V20.161C20.9672 20.5888 20.7973 20.9992 20.4949 21.3015C20.1923 21.6041 19.7822 21.774 19.3544 21.774L19.3545 21.7736ZM20.9671 4.83892V8.46773H13.7092V3.22584H19.3544C19.7822 3.22584 20.1923 3.39572 20.4949 3.69836C20.7973 4.00071 20.9672 4.41107 20.9672 4.83889"
                  fill="#9EB9E1"
                />
              </svg>

              <span>Dashboard</span>
            </router-link>
          </li> -->

          <li class="menu-item">
            <router-link to="/">
              <svg
                width="26"
                height="25"
                viewBox="0 0 26 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.68815 25H20.3149C21.5583 25 22.7506 24.5062 23.6297 23.6268C24.5091 22.7477 25.0029 21.5555 25.0029 20.312V10.0297C25.0032 8.33087 24.1543 6.74461 22.7411 5.80255L15.3184 0.852704H15.3181C14.484 0.296783 13.504 0 12.5015 0C11.4989 0 10.519 0.296762 9.68481 0.852704L2.2621 5.80255H2.26183C0.848588 6.74461 -0.00027898 8.33087 6.87777e-08 10.0297V20.312C6.87777e-08 21.5554 0.493785 22.7477 1.3732 23.6268C2.25234 24.5062 3.44451 25 4.68802 25H4.68815ZM3.12561 10.0297C3.12589 9.37624 3.4529 8.76639 3.99681 8.40447L11.4195 3.45463C11.7405 3.24044 12.1177 3.12626 12.5035 3.12626C12.8894 3.12626 13.2666 3.24044 13.5876 3.45463L21.0103 8.40447H21.0106C21.5528 8.76718 21.8783 9.37702 21.8777 10.0297V20.312C21.8777 20.7265 21.7131 21.1238 21.4202 21.417C21.1269 21.71 20.7296 21.8746 20.3151 21.8746H4.68838C4.27389 21.8746 3.87656 21.71 3.58335 21.417C3.2904 21.1238 3.1258 20.7265 3.1258 20.312L3.12561 10.0297Z"
                  fill="#9EB9E1"
                />
                <path
                  d="M11.5103 18.0262C11.7937 18.2459 12.142 18.365 12.5006 18.365C12.8589 18.365 13.2075 18.2459 13.4909 18.0262C14.8701 16.9637 17.1904 14.9284 17.1904 13.2797C17.1918 12.5496 16.8528 11.8607 16.2737 11.4162C15.6946 10.9718 14.9414 10.8225 14.2367 11.0124C13.5318 11.2023 12.9557 11.7097 12.6782 12.385C12.651 12.4586 12.5809 12.5074 12.5024 12.5074C12.424 12.5074 12.3539 12.4586 12.3267 12.385C12.0493 11.7098 11.4732 11.2024 10.7682 11.0124C10.0635 10.8224 9.3103 10.9718 8.7312 11.4162C8.15212 11.8607 7.81313 12.5496 7.81446 13.2797C7.81446 14.9284 10.1349 16.9637 11.5103 18.0262H11.5103Z"
                  fill="#9EB9E1"
                />
              </svg>

              <span>Marketplace</span>

              <div class="label label-new">
                <small>NEW</small>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "MainMenu",
  data() {
    return {
      pageWidth: window.innerWidth,
    };
  },
  computed: mapGetters(["isExpanded", "hoverExpand"]),
  methods: {
    ...mapMutations(["expandeToggle", "hoverExpandToggle"]),
    openSubMenu: function (event) {
      event.currentTarget.classList.toggle("opened");

      const subMenu = event.currentTarget.querySelector("ul");

      if (subMenu.offsetHeight > 0) {
        subMenu.style.height = "0px";
      } else {
        subMenu.style.height = subMenu.scrollHeight + "px";
      }
    },
    clearMenu: function () {
      let openedMenu = document.querySelectorAll(".menu-item.has-sub.opened");

      openedMenu.forEach((element) => {
        element.classList.remove("opened");
        element.querySelector("ul").style.height = "0px";
      });
    },
    windowResize: function () {
      this.pageWidth = window.innerWidth;
    },
  },
  created() {
    window.addEventListener("resize", this.windowResize);
  },
  watch: {
    $route() {
      this.clearMenu();
    },
  },
};
</script>

<style lang="scss">
.main-menu {
  position: fixed;
  top: calc(70px + 10px);
  background-color: #fff;
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  transform: translateZ(0);
  width: 260px;
  height: calc(100% - 70px - 30px);
  margin: 10px;
  border-radius: 20px;
  z-index: 99999;
  overflow: hidden;

  @media screen and (max-width: 991.98px) {
    transform: translateX(calc(-100% - 32px));
    height: calc(100% - 20px);
    top: 0;
  }

  &.expanded {
    width: 260px;

    .main-menu-content {
      .navigation-group {
        .group-name {
          h5 {
            display: block;
          }

          svg {
            display: none;
          }
        }
      }
    }

    .main-menu-footer {
      .socials {
        ul {
          li {
            &:not(:last-of-type) {
              margin-bottom: 0;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }

  &-content {
    padding: 24px 0;

    .navigation-group {
      .group-name {
        margin: 40px 30px 15px 30px;
      }

      ul {
        li {
          position: relative;
          cursor: pointer;
          white-space: nowrap;

          &:not(:last-of-type) {
            margin-bottom: 7px;
          }

          a {
            display: flex;
            align-items: center;
            padding: 10px 15px 10px 13px;
            font-size: 15px;
            margin: 0 15px;
            transition: padding 0.3s;

            &.router-link-active {
              &::before {
                position: absolute;
                left: 0;
                content: "";
                width: 3px;
                border-radius: 10px;
                background: #3f81e0;
                height: 100%;
              }

              svg {
                path {
                  fill: #3f81e0;
                }
              }

              span {
                color: #3f81e0;
              }
            }

            &:hover {
              opacity: 1;
              padding-left: 20px;
            }

            svg {
              margin-right: 16px;
              min-width: 22px;
              max-width: 22px;
              height: 22px;
            }

            span {
              color: #9eb9e1;
              font-weight: 600;
            }

            .label {
              display: flex;
              align-items: center;
              margin-left: 16px;
              border-radius: 6px;
              padding: 6px 8px;

              small {
                color: #fff;
                font-size: 11px;
                font-weight: 500;
                line-height: 1;
              }

              &-new {
                background: #e64335;
              }

              &-soon {
                background: #3f81e0;
              }
            }
          }
        }
      }
    }
  }
}
</style>