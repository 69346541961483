<template>
  <div class="navbar">
    <div class="container-fluid">
      <div class="row">
        <div class="navbar-left col-3 col-sm-8 p-0 d-flex align-items-center">
          <button class="menu-toggle" @click="showMenu()">
            <svg
              v-if="!menuToggleActive"
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.66667 21H23.3333C23.975 21 24.5 20.475 24.5 19.8333C24.5 19.1917 23.975 18.6667 23.3333 18.6667H4.66667C4.025 18.6667 3.5 19.1917 3.5 19.8333C3.5 20.475 4.025 21 4.66667 21ZM4.66667 15.1667H23.3333C23.975 15.1667 24.5 14.6417 24.5 14C24.5 13.3583 23.975 12.8333 23.3333 12.8333H4.66667C4.025 12.8333 3.5 13.3583 3.5 14C3.5 14.6417 4.025 15.1667 4.66667 15.1667ZM3.5 8.16667C3.5 8.80833 4.025 9.33333 4.66667 9.33333H23.3333C23.975 9.33333 24.5 8.80833 24.5 8.16667C24.5 7.525 23.975 7 23.3333 7H4.66667C4.025 7 3.5 7.525 3.5 8.16667Z"
                fill="black"
              />
            </svg>

            <svg
              v-else
              width="28"
              height="28"
              viewBox="0 0 28 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.66667 21H17.5C18.1417 21 18.6667 20.475 18.6667 19.8333C18.6667 19.1917 18.1417 18.6667 17.5 18.6667H4.66667C4.025 18.6667 3.5 19.1917 3.5 19.8333C3.5 20.475 4.025 21 4.66667 21ZM4.66667 15.1667H14C14.6417 15.1667 15.1667 14.6417 15.1667 14C15.1667 13.3583 14.6417 12.8333 14 12.8333H4.66667C4.025 12.8333 3.5 13.3583 3.5 14C3.5 14.6417 4.025 15.1667 4.66667 15.1667ZM3.5 8.16667C3.5 8.80833 4.025 9.33333 4.66667 9.33333H17.5C18.1417 9.33333 18.6667 8.80833 18.6667 8.16667C18.6667 7.525 18.1417 7 17.5 7H4.66667C4.025 7 3.5 7.525 3.5 8.16667ZM23.6833 17.36L20.3233 14L23.6833 10.64C24.1383 10.185 24.1383 9.45 23.6833 8.995C23.2283 8.54 22.4933 8.54 22.0383 8.995L17.85 13.1833C17.395 13.6383 17.395 14.3733 17.85 14.8283L22.0383 19.0167C22.4933 19.4717 23.2283 19.4717 23.6833 19.0167C24.1267 18.5617 24.1383 17.815 23.6833 17.36Z"
                fill="black"
              />
            </svg>
          </button>

          <div class="logo">
            <img src="@/assets/img/logo.svg" alt="" srcset="" />
          </div>

          <Search />
        </div>

        <!-- <div
          class="
            navbar-right
            col-9 col-sm-4
            p-0
            d-flex
            justify-content-end
            align-items-center
          "
        >
          <ul>
            <li class="dropdown" @click="dropdownToggle($event)">
              <a href="#" class="account d-flex align-items-center">
                <div class="name">
                  <h4>Hi, Stranger!</h4>
                  <small>It's just a DEMO</small>
                </div>

                <div class="avatar">👋</div>
              </a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

import Search from "@/components/custom-fields/Search";

export default {
  name: "Navbar",
  data() {
    return {
      pageTitle: "",
      menuToggleActive: false,
    };
  },
  components: {
    Search,
  },
  computed: mapGetters(["hoverExpand"]),
  methods: {
    ...mapMutations(["hoverExpandToggle"]),
    dropdownToggle: function (e) {
      let openedDropDown = document.querySelector(".dropdown.show");
      let dropDown = e.currentTarget;

      if (openedDropDown) openedDropDown.classList.remove("show");
      if (openedDropDown != dropDown) dropDown.classList.toggle("show");
    },
    textExpand: function (e) {
      let textField = e.currentTarget.querySelector("span");
      textField.style.width = textField.scrollWidth + "px";
    },
    textCollapse: function (e) {
      e.currentTarget.querySelector("span").style.width = 0;
    },
    showMenu: function () {
      document.querySelector(".layout").classList.toggle("menu-mobile-show");
      this.menuToggleActive = !this.menuToggleActive;
    },
  },
  created() {},
  watch: {
    $route() {
      this.pageTitle = this.$route.meta.pageTitle;
    },
  },
};
</script>

<style lang="scss">
.navbar {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  margin: 10px;
  width: calc(100% - 20px);
  z-index: 9999;
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);
  background: #fff;
  border-radius: 20px;

  .row {
    padding: 10px 18px;
  }

  &-left {
    .menu-toggle {
      display: none;
      align-items: center;
      border: none;
      background: none;
      cursor: pointer;
      padding: 0;
      margin-right: 10px;

      @media screen and (max-width: 991.98px) {
        display: flex;
      }
    }

    .logo {
      margin-right: 30px;
    }

  }

  &-right {
    .account {
      position: relative;
      color: #565656;
      cursor: default;

      .name {
        text-align: right;

        h4 {
          font-weight: 600;
        }
      }

      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
        background-color: rgba(#3f81e0, 0.12);
        color: #3f81e0;
        margin-left: 10px;
      }
    }

    .dropdown {
      position: relative;

      &-menu {
        position: absolute;
        top: 60px !important;
        right: 0;
        left: auto;
        transform: none;
        width: 160px;
      }
    }
  }
}
</style>