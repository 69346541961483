import axios from "axios";
import { apiURL } from "@/helpers/utils";

export default {
  state: {
    coinList: []
  },
  actions: {
    fetchCoinList(ctx) {
      axios
        .get(`${apiURL}/coin`)
        .then((response) => {
          const newArr = []

          response.data.forEach(element => {
            newArr.push(element.coin)
          });

          ctx.commit('updateCoinList', newArr);
        });
    }
  },
  getters: {
    getCoinList(state) {
      return state.coinList
    },
    getCoinPrice: (state) => (coinID) => {
      if (state.coinList.length > 0) return state.coinList.find(coin => coin.id === coinID).quote.USD.price
    }
  },
  mutations: {
    updateCoinList(state, coinList) {
      state.coinList = coinList
    }
  }
}