<template>
  <div
    class="layout h-100"
    :class="[
      isExpanded ? 'menu-expanded' : 'menu-collapsed',
      hoverExpand ? 'main-menu-expanded' : '',
    ]"
  >
    <MainMenu />
    <Navbar />
    <div class="app-content">
      <div class="navbar-shadow"></div>
      
      <router-view v-slot="{ Component }">
        <keep-alive include="Marketplace">
          <component :is="Component" />
        </keep-alive>
      </router-view>
      
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

import nftArray from "@/utils/_metadata.json";

import MainMenu from "@/components/MainMenu";
import Navbar from "@/components/Navbar";

export default {
  name: "App",
  data() {
    return {
      pageWidth: window.innerWidth,
    };
  },
  computed: mapGetters(["isExpanded", "hoverExpand"]),
  components: {
    MainMenu,
    Navbar,
  },
  methods: {
    ...mapMutations(["expandeToggle", "SET_NFTS"]),
    ...mapActions(["fetchCoinList"]),
    windowResize: function () {
      this.pageWidth = window.innerWidth;

      if (window.innerWidth <= 991.98) this.expandeToggle(true);
      else this.expandeToggle(false);
    },
  },
  created() {
    window.addEventListener("resize", this.windowResize);
    this.windowResize();

    document.addEventListener("click", (event) => {
      let el = document.querySelector(".dropdown.show");

      if (el) {
        // el = el.querySelector("button");

        if (el !== event.target && !el.contains(event.target)) {
          document.querySelector(".dropdown.show").classList.remove("show");
        }
      }
    });

    this.SET_NFTS(nftArray);

    this.fetchCoinList();
  },
  $route() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss">
@import "./assets/css/normalize.css";
@import "./assets/css/bootstrap-grid.min.css";
@import "./assets/css/bootstrap-utilities.min.css";
// @import "./assets/font/font.css";

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

* {
  outline: none;
  box-sizing: border-box;
}

html {
  // font-family: "TT Norms Pro";
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
}

body {
  position: relative;
  padding: 0;
  margin: 0;
  background-color: #eff3f9;
  color: #161d2f;
}

a {
  text-decoration: none;
  transition: all 0.3s;
}

ul,
nav {
  padding: 0;
  margin: 0;
  list-style-type: none;

  li {
    list-style: none;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 500;
}

p {
  font-style: normal;
  font-weight: 400;
  line-height: 1.45;
}

img {
  max-width: 100%;
  height: auto;
}

.grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-flow: row;
}

#app {
  height: 100% !important;
  overflow-x: hidden;
}

// Expanded menu

.menu-collapsed {
  .main-menu {
    width: 80px;

    &.expanded {
      width: 260px;

      .main-menu-header {
        .collapse-toggle {
          &-icon {
            display: unset;
          }
        }
      }

      .main-menu-content {
        ul {
          li {
            a {
              span {
                display: unset;
              }
            }
          }
        }
      }
    }

    &-content {
      .navigation-group {
        .group-name {
          h5 {
            display: none;
          }

          svg {
            display: block;
          }
        }
      }

      ul {
        li {
          a {
            span {
              display: none;
            }
          }
        }
      }
    }
  }

  .app-content {
    margin-left: 80px;
  }
}

.menu-mobile-show {
  .app-content,
  .navbar {
    @media screen and (max-width: 991.98px) {
      transform: translateX(calc(260px + 24px));
    }

    @media screen and (max-width: 575.98px) {
      transform: translateX(calc(260px + 24px));
    }
  }

  .main-menu {
    transform: translateX(0);
  }
}

.app-content {
  margin-left: 280px;
  padding: 92px 10px 0 20px;
  min-height: calc(100% - 3.35rem);
  transition: 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);

  @media screen and (max-width: 991.98px) {
    margin-left: 0 !important;
    padding-left: 10px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: #fff;
  border-radius: 18px;
  padding: 20px;
  user-select: none;
  box-shadow: 0 0 15px 0 rgba(34, 41, 47, 0.05);

  @media screen and (max-width: 575.98px) {
    padding: 18px !important;
  }

  &-box {
    padding: 0 12px !important;
    margin-bottom: 24px !important;

    @media screen and (max-width: 575.98px) {
      padding: 0 12px !important;
      margin-bottom: 24px !important;
    }
  }

  &-transparent {
    background-color: transparent;
    box-shadow: none;
  }

  &-title {
    font-weight: 800;
    font-size: 24px;
    margin-bottom: 20px;
    color: #161d2f;
  }
}

.navbar-shadow {
  display: block;
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  z-index: 999;
  padding-top: 20px;
  background-repeat: repeat;
  background: linear-gradient(
    180deg,
    rgba(#eff3f9, 0.6) 45%,
    rgba(#eff3f9, 0.4) 75%,
    hsla(0, 0%, 100%, 0)
  );
  left: 0;
}

.success {
  color: #28c76f;
}

.danger {
  color: #ea5455;
}

.logo {
  display: flex;
  align-items: center;
  color:  #3f81e0;

  img {
    max-width: 40px;
  }

  span {
    font-size: 14px;
    font-weight: 700;
    line-height: 1;

    small {
      font-weight: 500;
    }
  }
}

/* Form */

.form-group {
  margin-bottom: 1rem;

  label {
    display: block;
    color: #161d2f;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 0.4rem;
  }

  .custom-control {
    &.custom-checkbox {
      display: flex;
      align-items: center;

      input[type="checkbox"] {
        width: auto;
      }

      label {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 0;
        margin-left: 8px;
      }
    }
  }
}

/* Badge */

.badge {
  display: inline-block;
  border-radius: 12px;
  padding: 10px 16px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  user-select: none;

  &-white {
    background-color: #fff;
    color: #565656;
  }

  &-number {
    background-color: #4cc3ce;
  }

  &-success {
    background-color: rgba(40, 199, 111, 0.12);
  }

  &-error {
    background-color: rgba(234, 84, 85, 0.12);
  }

  &-blue {
    background-color: rgba($color:  #3f81e0, $alpha: 0.12);
    color:  #3f81e0;
  }

  &-filter-type {
    background: #f8faff;
    padding: 4px 8px;

    img {
      max-width: 22px;
      margin-right: 8px;
    }

    span {
      font-size: 13px;
      color: #161d2f;
      font-weight: 500;
    }
  }
}

/* inputs */

// input:not(input[type="file"]) {
//   display: block;
//   width: 100%;
//   font-size: 1rem;
//   font-weight: 400;
//   line-height: 1.45;
//   color: #6e6b7b;
//   padding: 0.438rem 1rem;
//   background-color: #fff;
//   border: 1px solid #d8d6de;
//   border-radius: 6px;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

//   &:focus {
//     background-color: #fff;
//     border-color:  #3f81e0;
//     box-shadow: 0 3px 10px 0 rgba(34, 41, 47, 0.1);
//   }
// }

// input[type="file"] {
//   display: block;
//   width: 100%;
// }

/* Buttons */

.btn {
  display: inline-block;
  line-height: 1;
  border-color:  #3f81e0;
  background:  #3f81e0;
  text-align: center;
  font-weight: 500;
  color: #fff;
  border: none;
  padding: 12px 22px;
  border-radius: 14px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    opacity: 0.7;
    cursor: default;
  }

  &-danger {
    color: #fff;
    background: #ea5455;
    border: 1px solid #ea5455;
  }

  &-outline-danger {
    background: transparent;
    color: #ea5455;
    border: 1px solid #ea5455;

    &:hover {
      background-color: rgba(234, 84, 85, 0.04);
      opacity: 1;
    }
  }
}

/* Tables */

table {
  width: 100%;
  color: #6e6b7b;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-collapse: collapse;

  thead {
    th {
      background-color: #f8faff;
      border-bottom: 2px solid #f8faff;
      padding: 0.72rem 1.5rem;
      border-top: 1px solid #f8faff;
      vertical-align: top;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-align: left;
    }
  }

  tbody {
    td {
      vertical-align: middle;
      padding: 0.72rem 1.5rem !important;
      max-width: 250px;
      font-size: 15px;

      &:not(&.actions) {
        overflow-x: auto;
      }

      .table-user {
        display: flex;
        align-items: center;

        .img {
          margin-right: 12px;
          width: 34px;
          height: 34px;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
          white-space: nowrap;
          background-color: rgba( #3f81e0, 0.12);
          color:  #3f81e0;
        }

        .name {
          h5 {
            font-size: 16px;
            color:  #3f81e0;
          }

          small {
            font-size: 10px;
          }
        }
      }

      .date-time {
        display: flex;
        flex-direction: column;
      }

      a {
        color:  #3f81e0;
      }
    }
  }
}

/* Statistics */

.statisctics-col {
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-of-type) {
    border-right: 1px solid rgba(130, 134, 139, 0.12);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 6px;

    img {
      max-width: 20px;
    }

    &-default {
      background-color: rgba(130, 134, 139, 0.12);
    }

    &-success {
      background-color: rgba(40, 199, 111, 0.12);
    }

    &-warning {
      background-color: rgba(255, 159, 67, 0.12);
    }

    &-danger {
      background-color: rgba(234, 84, 85, 0.12);
    }
  }

  .text {
    margin-left: 1rem;

    h5 {
      color: #161d2f;
      font-size: 20px;
      font-weight: 500;
    }
  }
}

/* Dropdown */

.dropdown {
  position: relative;
  display: flex;
  justify-content: center;

  button {
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.358rem;
    cursor: pointer;

    img {
      max-width: 16px;
    }
  }

  &.show {
    ul {
      opacity: 1;
      display: block;
    }
  }

  ul {
    box-shadow: 0 5px 25px rgba(34, 41, 47, 0.1);
    border-radius: 0.358rem;
    text-align: left;
    padding: 6px 0;
    margin: 0 0 0;
    background-color: #fff;
    border: 1px solid rgba(34, 41, 47, 0.05);
    display: none;
    opacity: 0;
    position: absolute;
    transform: translate3d(-48px, 40px, 0px);
    top: 0px;
    left: 0px;
    z-index: 99999;

    li {
      a {
        display: flex;
        align-items: center;
        padding: 0.65rem 1.28rem;
        font-size: 15px;
        font-weight: 400;
        color: #6e6b7b;
        white-space: nowrap;

        svg {
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }

        &:hover {
          background-color: rgba( #3f81e0, 0.12);
          color:  #3f81e0;

          svg {
            path {
              fill:  #3f81e0;
            }
          }
        }
      }
    }
  }
}

// Micromodal

.modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.modal__container {
  position: relative;
  background: transparent;
  box-shadow: 0px 8px 30px rgba(26, 47, 69, 0.5);
  border-radius: 18px;
  max-width: 75vw;
  transition: all 0.3s;

  @media only screen and (max-width: 575.98px) {
    max-width: 95%;
    max-height: 95%;
  }
}

.close-modal-button {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  top: -10px;
  right: -10px;
  font-size: 14px;
  font-weight: 300;
  cursor: pointer;
  transition: all 0.3s;
  background: #fff;
  border-radius: 50%;
  border: 1px solid rgba($color: #cdcdcd, $alpha: 0.4);
}

// Animation

@keyframes mmfadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes mmfadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes mmslideIn {
  from {
    transform: translateY(15%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes mmslideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10%);
  }
}

.micromodal-slide {
  display: none;
}

.micromodal-slide.is-open {
  display: block;
}

.micromodal-slide[aria-hidden="false"] .modal__overlay {
  animation: mmfadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="false"] .modal__container {
  animation: mmslideIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__overlay {
  animation: mmfadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide[aria-hidden="true"] .modal__container {
  animation: mmslideOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
  will-change: transform;
}

// Custom

.modal-transaction {
  .modal__content {
    text-align: center;

    svg {
      margin-bottom: 20px;
    }

    h5 {
      font-size: 24px;
      margin-bottom: 16px;
    }

    a {
      display: block;
      color:  #3f81e0;
      font-weight: 500;
      margin-bottom: 16px;
    }

    small {
      display: block;
      font-size: 14px;
      margin-bottom: 16px;
    }
  }
}

// Notifications

.mosha__toast {
  z-index: 9999999999 !important;

  a {
    color: #fff;
  }
}

.mosha__toast__content__description {
  font-size: 16px !important;
  font-family: "TT Norms Pro";
}

// Tooltip

.has-tooltip {
  position: relative;
  transition: all 0.15s;

  &:hover,
  &:focus,
  &.active {
    .tooltip {
      transform: scale(1);
      opacity: 1;
    }
  }

  .tooltip {
    position: absolute;
    background-color: #30305a;
    padding: 8px 12px;
    border-radius: 8px;
    left: calc(50% - 145px);
    bottom: calc(100% + 10px);
    transform-origin: center bottom;
    transform: scale(0);
    opacity: 0;
    max-width: 290px;
    min-width: 290px;
    transition: 0.15s ease;

    span {
      white-space: normal;
      color: #fff;

      a {
        color: #ffbf71;
        transition: 0.3 all;
        &:hover {
          opacity: 0.8;
        }
      }
    }

    &:before {
      content: "";
      display: block;
      width: 12px;
      height: 12px;
      position: absolute;
      background-color: #30305a;
      bottom: -5px;
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
      border-radius: 3px;
    }
  }
}
</style>