import { createRouter, createWebHistory } from 'vue-router'

const withPrefix = (prefix, routes) =>
  routes.map((route) => {
    route.path = prefix + route.path;
    return route;
  });

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: () => import('../views/Dashboard.vue'),
    component: () => import('../views/Marketplace.vue'),
  },
  ...withPrefix('/marketplace', [
    {
      path: '',
      component: () => import('../views/Marketplace.vue'),
    },
    {
      path: '/:id',
      component: () => import('../views/marketplace/MarketplaceItemView.vue')
    },
  ]),
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
